(function($) {
	/* MAIN CLASS */

	$.extend(window.app, {
		popup: new function() {

			/* INIT */
			this.init = function() {};

			/* RESIZE */
			this.resize = function() {};

			/* SCROLL */
			this.scroll = function() {};
		}
	});


	/* SCRIPT INIT */
	window.app.popup.init();


	/* DOM READY */
	$(document).ready(function() {

	});

})(jQuery);