(function($) {

	/* GLOBAL CONFIGURATION APPLY ALL TO WINDOW */
	window.transitionEnd = window.transitionEnd || "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend";

	/* APPLICATION MAIN CLASS */
	window.app = new function() {

		/* INIT */
		this.init = function() {

			function PopupCenter(url, title, w, h) {
				var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
				var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

				var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
				var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

				var left = ((width / 2) - (w / 2)) + dualScreenLeft;
				var top = ((height / 2) - (h / 2)) + dualScreenTop;
				var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

				// Puts focus on the newWindow
				if (window.focus) {
					newWindow.focus();
				}
			}

			$('header#header .share-facebook').bind('click touch', function() {
				FB.ui({
					method: 'share',
					href: window.location.href,
				}, function(response) {});
			});

			$('.share-twitter').on('click touch', function(event) {
				PopupCenter($(this).attr('href'), 'Partilhar', '800', '450');
				event.preventDefault();
			});

			$('.modal-open').each(function(index, el) {
				var title = $(this).attr("href");
				$(this).attr("onclick", "ga('send', 'event', '" + $('#produto').val() + "', 'Click', '" + title + "');");
			});


			$('#header button.menu').bind('click touch', function() {
				$('#header').toggleClass('active');
			});

			$('.slick').slick({
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: true,
				arrows: false,
				autoplay: true,
				autoplaySpeed: 6000
			});

			$('.modal-open').magnificPopup({
				type: 'inline',
				fixedContentPos: false,
				fixedBgPos: true,
				overflowY: 'auto',
				closeBtnInside: true,
				preloader: false,
				midClick: true,
				removalDelay: 300,
				mainClass: 'my-mfp-slide-bottom',
				callbacks: {
					open: function() {
						modal = $(this)[0].currItem.src.replace('#modal-', '/');
						History.pushState({state: 1}, 'Frescos Continente', window.location.href + modal);
					},
					close: function() {
						modal = window.location.href.replace(modal, '');
						History.pushState({state: 0}, 'Frescos Continente', modal);
					}
				}
			});

			// Get state change of history and close modal on history.back
			History.Adapter.bind(window,'statechange',function(){
				if (History.getState().data.state != 1) {
					if ($.magnificPopup.instance.isOpen) {
						$.magnificPopup.close();
					}
				}
			});


			// Get modal
			if ($('#modal-url')[0]) {
				value = $('#modal-url').val();
				var modal = '#modal-' + value;

				$.magnificPopup.open({
					items: {
						src: modal
					},
					type: 'inline',
					callbacks: {
						beforeClose: function() {
							modal = window.location.href.replace('/' + value, '');
							History.pushState({state: 1}, 'Frescos Continente', modal);
						},
						close: function() {
							modal = window.location.href.replace(modal, '');
							History.pushState({state: 0}, 'Frescos Continente', modal);
						}
					}
				}, 0);
			}
		};


		/* RESIZE */
		this.resize = function() {};


		/* SCROLL */
		this.scroll = function() {};
	};


	/* SCRIPT INIT */
	window.app.init();


	/* DOM READY */
	$(document).ready(function() {

	});

})(jQuery);