(function($) {

	/* GLOBAL CONFIGURATION APPLY ALL TO WINDOW */
	window.transitionEnd = window.transitionEnd || "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend";

	/* APPLICATION MAIN CLASS */
	window.app = new function() {

		/* INIT */
		this.init = function() {};


		/* RESIZE */
		this.resize = function() {};


		/* SCROLL */
		this.scroll = function() {};
	};


	/* SCRIPT INIT */
	window.app.init();


	/* DOM READY */
	$(document).ready(function() {

	});

})(jQuery);